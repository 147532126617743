.Home-div {
    text-align: center;
  }
 
  .Home-header {
    background-image: url("../../img/Niagara_Falls.png");
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  